
export default {
  data() {
    return {
      categorias: [
        {
          cat: 'Carreiras',
          img: 'icone-carreiras.png',
          slug: 'carreiras',
        },
        {
          cat: 'Informática',
          img: 'icone-informatica.png',
          slug: 'informatica',
        },
        {
          cat: 'Inglês',
          img: 'icone-ingles.png',
          slug: 'ingles',
        },
        {
          cat: 'Robótica',
          img: 'icone-robotica.png',
          slug: 'robotica',
        },
      ],
    }
  },
}
