
export default {
  data() {
    return {
      slides: [
        {
          text1: 'O FUTURO DA <br/>SUA CARREIRA <br/>COMEÇA AQUI',
          text2: 'Estude na Technos Multiversa',
        },
        {
          text1: 'CONECTAMOS SEUS <br/>FILHOS À NOVA <br/>REALIDADE!',
          text2: 'Robótica, Inglês e Informática Interativos!',
        },
      ],
    }
  },
}
